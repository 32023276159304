import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import DiscourseURL from "discourse/lib/url";
import { getWebauthnCredential } from "discourse/lib/webauthn";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("model"), (_obj = {
  router: service(),
  secondFactorMethod: null,
  secondFactorToken: null,
  lockImageUrl: getURL("/images/lock.svg"),
  secondFactorRequired(model) {
    return model.security_key_required || model.second_factor_required;
  },
  async finishLogin() {
    let data = {
      second_factor_method: this.secondFactorMethod,
      timezone: moment.tz.guess()
    };
    if (this.securityKeyCredential) {
      data.second_factor_token = this.securityKeyCredential;
    } else {
      data.second_factor_token = this.secondFactorToken;
    }
    try {
      const result = await ajax({
        url: `/session/email-login/${this.model.token}`,
        type: "POST",
        data
      });
      if (!result.success) {
        this.set("model.error", result.error);
        return;
      }
      let destination = "/";
      const safeMode = new URL(this.router.currentURL, window.location.origin).searchParams.get("safe_mode");
      if (safeMode) {
        const params = new URLSearchParams();
        params.set("safe_mode", safeMode);
        destination += `?${params.toString()}`;
      }
      DiscourseURL.redirectTo(destination);
    } catch (e) {
      popupAjaxError(e);
    }
  },
  authenticateSecurityKey() {
    getWebauthnCredential(this.model.challenge, this.model.allowed_credential_ids, credentialData => {
      this.set("securityKeyCredential", credentialData);
      this.send("finishLogin");
    }, errorMessage => {
      this.set("model.error", errorMessage);
    });
  }
}, (_applyDecoratedDescriptor(_obj, "secondFactorRequired", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorRequired"), _obj), _applyDecoratedDescriptor(_obj, "finishLogin", [action], Object.getOwnPropertyDescriptor(_obj, "finishLogin"), _obj), _applyDecoratedDescriptor(_obj, "authenticateSecurityKey", [action], Object.getOwnPropertyDescriptor(_obj, "authenticateSecurityKey"), _obj)), _obj)));