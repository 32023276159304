import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _SecurityKeyForm;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SecurityKeyForm = (_class = (_SecurityKeyForm = class SecurityKeyForm extends Component {
  useAnotherMethod(event1) {
    event1.preventDefault();
    this.args.setShowSecurityKey?.(false);
    this.args.setShowSecondFactor?.(true);
    if (this.args.totpEnabled) {
      this.args.setSecondFactorMethod?.(SECOND_FACTOR_METHODS.TOTP);
    } else if (this.args.backupEnabled) {
      this.args.setSecondFactorMethod?.(SECOND_FACTOR_METHODS.BACKUP_CODE);
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div id="security-key">
      <DButton
        @action={{@action}}
        @icon="key"
        @label="login.security_key_authenticate"
        id="security-key-authenticate-button"
        class="btn-large btn-primary"
      />
      <p>
        {{#if @otherMethodAllowed}}
          <a
            {{on "click" this.useAnotherMethod}}
            href
            class="toggle-second-factor-method"
          >{{i18n "login.security_key_alternative"}}</a>
        {{/if}}
      </p>
    </div>
  
*/
{
  "id": "YeYNXmBM",
  "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"security-key\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,1,\"security-key-authenticate-button\"],[24,0,\"btn-large btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,1],\"key\",\"login.security_key_authenticate\"]],null],[1,\"\\n      \"],[10,2],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"toggle-second-factor-method\"],[4,[32,1],[\"click\",[30,0,[\"useAnotherMethod\"]]],null],[12],[1,[28,[32,2],[\"login.security_key_alternative\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@action\",\"@otherMethodAllowed\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/security-key-form.js",
  "scope": () => [DButton, on, i18n],
  "isStrictMode": true
}), _SecurityKeyForm), _SecurityKeyForm), (_applyDecoratedDescriptor(_class.prototype, "useAnotherMethod", [action], Object.getOwnPropertyDescriptor(_class.prototype, "useAnotherMethod"), _class.prototype)), _class);
export { SecurityKeyForm as default };